jQuery(document).ready(function($){
    function inview_animate(elem) {
        new Waypoint.Inview({
            element: elem,
            enter: function (direction) {
                $(this.element).addClass($(elem).data('animate') + ' animated');
            }
        })
    }

    $('[data-animate]').each(function () {
        inview_animate(this);
    });

    $('.home-section1').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var slide = slick.$slides.eq(nextSlide);
        slide.find('.white-box').hide().removeClass('zoomIn animated');
    });

    $('.home-section1').on('afterChange', function (event, slick, currentSlide) {
        var slide = slick.$slides.eq(currentSlide);
        slide.find('.white-box').addClass('animated zoomIn').show();
    });

});
