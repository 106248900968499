jQuery(document).ready(function($){

    $('.tooltip-store').tooltipster({
        contentAsHTML: true,
        // interactive: true,
        // trigger: 'click',
        animation: 'fade',
        delay: 200,
        minWidth: 150,
        functionBefore: function(instance, helper) {
            var $origin = $(helper.origin);

            var tooltip_id = $origin.attr('id');
            var sel = '#tooltip-' + tooltip_id + ' .tabs-panel6__item__tooltip';
            var data = null;

            if ($(sel).length) {
                data = $(sel).html();
            }

            if (data) {
                instance.content(data);
            } else {
                return false;
            }
        }

    });

    $('.tooltip-store').on('click mouseover', function() {
        var was_active = $(this).hasClass('active');
        $('.tooltip-store').removeClass('active');

        if (!was_active) {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });

    $('.tooltip-store').on('mouseout', function() {
        $('.tooltip-store').removeClass('active');
    });

    $('.tabs-panel6__item').on('click mouseover', function() {
        $('.tooltip-store').removeClass('active');
        var sel = $(this).data('target');

        if (sel) {
            var $el = $('#' + sel);

            if ($el.length) {
                $el.addClass('active');
                $el.tooltipster('open');
            }
        }
    });

    $('.tabs-panel6__item').on('mouseout', function() {
        var sel = $(this).data('target');

        if (sel) {
            var $el = $('#' + sel);

            if ($el.length) {
                $el.removeClass('active');
                $el.tooltipster('close');
            }
        }
    });

});
